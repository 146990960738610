<template>
  <v-sheet class="customer" id="customer">
    <v-row>
      <v-col md="12">
        <!-- <pre>  {{ purchaseOrder.show_only_supplier_products}}</pre> -->
        <!-- <pre>{{purchaseOrder.supplier}}</pre> -->

        <v-row>
          <v-col md="8" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">
              <!-- <pre>{{ line_items }}</pre> -->
              {{ pageTitle() }} {{ purchaseOrder.barcode }}
              <!-- <pre>{{ line_items }}</pre> -->
            </h1>
          </v-col>
          <v-col md="4" class="text-right py-0">
            <v-btn :disabled="pageLoading || !formValid" :loading="pageLoading" class="white--text mr-2" depressed
              color="red darken-4" tile v-on:click="updateOrCreate(0)">
              Save as Draft
            </v-btn>
            <v-btn :disabled="pageLoading || !formValid" :loading="pageLoading" class="white--text mr-2" depressed
              color="blue darken-4" tile v-on:click="updateOrCreate(1)">
              Save as Aprroval
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="mt-0 pt-0">
        <v-form ref="productAddForm" v-model.trim="formValid" lazy-validation v-on:submit.stop.prevent="updateOrCreate"
          style="height: calc(100vh - 150px); overflow-y: auto">
          <v-container fluid class="pt-0">
            <v-row>
              <!-- with no more values -->
              <v-col md="9" class="pt-0">
                <v-row class="pt-0 p-4 pl-1">
                  <v-col md="12" class="">
                    <v-row>
                      <v-col md="2" class="my-auto py-0 pr-0">
                        <label for="ref-number" class="field-label">Ref Number
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="disabled" size="18">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>Reference Number Printed on pdf</span>
                          </v-tooltip>
                        </label>
                      </v-col>
                      <v-col md="4" class="my-2 py-0">
                        <TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="ref-number"
                          placeholder="Ref Number" v-model="purchaseOrder.refNumber">
                        </TextInput>
                      </v-col>

                      <v-col md="2" class="my-auto py-0 text-end pr-0">
                        <label class="field-label required">Delivery Date
                        </label>
                      </v-col>
                      <v-col md="4" class="my-0 py-0">
                        <DatePicker  hide-details :disabled="pageLoading" :loading="pageLoading"
                          id="delivery-date" v-model="purchaseOrder.delivery_date" placeholder="Delivery Date">
                        </DatePicker>
                      </v-col>

                      <v-col md="2" class="py-0 my-auto">
                        <label class="field-label my-0 required">Supplier</label>
                      </v-col>
                      <v-col md="10" class="my-2 py-0">
                        <label class="field-label required">Note: Changing supplier will reset all line items.</label>
                        <!-- {{ supplierList }} -->

                        <v-autocomplete outlined hide-details :items.sync="supplierList" :disabled="pageLoading"
                          :loading="pageLoading" id="supplier" item-text="barcode" item-value="value"
                          v-on:change="selectSupplier($event)" v-model="purchaseOrder.supplier"
                          placeholder="Select Supplier" :rules="[vrules.required(purchaseOrder.supplier, 'Supplier')]"
                          :class="{ required: !purchaseOrder.supplier }">
                        </v-autocomplete>
                      </v-col>
                      <v-col md="2" class="py-0"></v-col>
                      <v-col md="10" class="py-0">
                        <v-row class="" v-if="purchaseOrder.suppliers">
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">SUPPLIER</h6>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                              <span>{{ purchaseOrder?.suppliers?.company_name }}</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                              <span v-if="purchaseOrder?.suppliers?.company_email"> {{ purchaseOrder?.suppliers?.company_email }}</span>
                              <span v-else>No Email</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                              <span v-if="purchaseOrder?.suppliers?.company_phone">{{ purchaseOrder?.suppliers?.company_phone }}</span>
                              <span v-else>No Phone</span>

                            </div>
                          </v-col>
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">PERSON INCHARGE</h6>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                              <span>{{ purchaseOrder?.suppliers?.persons_name }}</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                              <span v-if="purchaseOrder?.suppliers?.persons_email">{{ purchaseOrder?.suppliers?.persons_email }}</span>
                              <span v-else>No Email</span>

                            </div>
                          </v-col>
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">ADDRESS</h6>
                            <div>
                              <div class="d-flex">
                                <div>
                                  <v-icon color="disabled" size="18" class="me-1">mdi-map</v-icon>
                                </div>
                                <span>
                                  {{ purchaseOrder?.suppliers?.address_line_1 }}<br />
                                  {{ purchaseOrder?.suppliers?.address_line_2 }}<br />
                                  {{ purchaseOrder?.suppliers?.country }}<br />
                                  {{ purchaseOrder?.suppliers?.postal_code }}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="2" class="pb-0 my-auto">
                        <label for="dept-category" class="field-label mt-0 mb-1"></label>
                      </v-col>
                      <v-col md="10" class="my-0 py-0">
                        <!-- {{ purchaseOrder.show_only_supplier_products }} -->
                        <v-checkbox hide-details label="Show Only this supplier product" class="my-2 p-0"
                          @change="showSupplierProductChange(purchaseOrder.show_only_supplier_products)"  v-model="purchaseOrder.show_only_supplier_products"></v-checkbox>

                        <!-- <v-checkbox
                          v-model="purchaseOrder.supplier_product"
                          label="Show Only this supplier product"
                          value="Show Only this supplier product"
                        ></v-checkbox> -->
                      </v-col>
                      <v-col md="2" class="py-0 my-auto">
                        <label for="received-status" class="field-label my-0">Received Status</label>
                      </v-col>
                      <v-col md="4" class="my-0 py-0">
                        <v-btn-toggle v-model="received_status" tile group divided colo blue variant="plain" mandatory>
                          <v-btn color="green" height="34" variant="flat" style="border: 2px solid rgb(34, 167, 93)">
                            Received
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>

                      <v-col md="2" class="my-auto py-0 text-end">
                        <label class="field-label">Receiving Warehouse </label>
                      </v-col>
                      <v-col md="4" class="my-0 py-0">
                        <v-btn-toggle v-model="purchaseOrder.receivingAdd" tile group divided colo blue variant="plain"
                          mandatory>
                          <v-btn color="green" height="34" variant="flat" style="border: 2px solid rgb(34, 167, 93)">
                            Sutra
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="3" class="pt-0">
                <div class="py-0 text-right col-md-12 col">
                  <span class="font-weight-bold" style="font-size: 24px">Amount</span><br />
                  <div v-if="discount_level !== 'lineItems'" class="blue--text text--darken-4"
                    style="font-size: 40px; font-weight: 700">
                    {{ formatMoney(getGrandTotal()) }}
                  </div>
                  <div v-else class="blue--text text--darken-4" style="font-size: 40px; font-weight: 700">
                    {{ formatMoney(getGrandTotals()) }}
                  </div>
                </div>
              </v-col>
              <!-- Line items -->
              <v-col md="12" class="blue lighten-5">
                <v-row>
                  <v-col md="6" class="my-auto py-0">
                    <p class="my-0 font-weight-bold">Line items</p>
                    <span><v-icon color="disabled" size="18">mdi-help-circle-outline</v-icon>Specify
                      the Line Items for your Purchase order</span>
                  </v-col>
                  <!-- <v-col md="6" class="text-right py-0">
                    <v-btn
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="white--text mr-2"
                      depressed
                      color="blue darken-4"
                      tile
                      v-on:click="importDialog = true"
                    >
                      <v-icon color="disabled" size="15">mdi-file-excel</v-icon>
                      UPload Excel
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col md="3" class="py-2">
                <div class="d-flex align-center">
                  <TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="search-line-item"
                    class="mt-0" placeholder="Search Line Item.." v-model="search_line_item">
                  </TextInput>
                  <v-btn :disabled="searchButton" style="height: 35px" class="white--text" depressed color="blue darken-4"
                    v-on:click="getProduct()" tile>
                    <v-icon left>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col md="9" class="py-2 d-flex justify-content-end">
                <label class="field-level mt-2 me-2 font-weight-bold">Discount Level:</label>
                <v-radio-group v-model="discount_level" row v-on:change="getData($event)">
                  <v-radio label="Line item" value="lineItems"></v-radio>
                  <v-radio label="Transaction" value="transaction"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col md="12" class="pt-0">
                <div class="mb-5 position-relative" style="max-height: calc(100vh - 300px); overflow-y: auto">
                  <!-- 👉 Item Header -->

                  <div class="w-100 po-line-items">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th class="pa-2" width="40"></th>
                          <th class="pa-2 text-center" width="40">#</th>
                          <th class="pa-2" width="300">Product</th>
                          <th class="pa-2" width="300">Description</th>
                          <th class="pa-2" width="150">UOM</th>
                          <th class="pa-2" width="120">Qty</th>
                          <th class="pa-2 text-right" width="120">Rate</th>
                          <th class="pa-2 text-right" width="120">Amount</th>
                          <th class="pa-2 text-right" width="120" v-if="discount_level == 'lineItems'">
                            Discount
                          </th>
                          <th class="pa-2 text-right" width="40"></th>
                        </tr>
                      </thead>
                      <!-- <tbody > -->
                      <Draggable tag="tbody" v-model="line_items" class="draggable-group" handle=".draggable-drag-icon"
                        v-on:change="updateItemOrder($event)">
                        <tr v-for="(row, index) in line_items" :key="index">
                          <td class="py-1 px-2">
                            <v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>
                          </td>
                          <td class="py-1 px-2 text-center">{{ index + 1 }}</td>
                          <td class="py-1 px-2" @click="editRow($event, index)" :class="row.isEditable ? 'px-2' : 'px-3'">
                            <template v-if="row.isEditable">
                              <div class="d-flex align-center">
                                <div>
                                  <v-avatar size="40px">
                                    <img max-height="40" max-width="40"
                                      src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
                                      alt="John" />
                                  </v-avatar>
                                </div>
                                <!-- <v-autocomplete
                                    outlined
                                    hide-details
                                    :items.sync="productList"
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    id="supplier"
                                 
                                    v-on:change="searchProduct($event,index)"
                                    v-model="row.product"
                                    placeholder="Select Supplier"
                                    :rules="[vrules.required(purchaseOrder.supplier, 'Supplier')]"
                                    :class="{ required: !purchaseOrder.supplier }"
                                  >
                                  </v-autocomplete> -->

                                <v-autocomplete hide-details :disabled="pageLoading" :loading="pageLoading"
                                  :items="productListsss(line_items[index].product)" id="supplier" outlined
                                  v-model="line_items[index].product" @change="searchProduct(row, index)" append-outer-icon="mdi-plus" @click:append-outer="openCreateProduct()">
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-avatar>
                                        <img :src="data.item.avatar" />
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </div>
                            </template>
                            <template v-else class="px-1">
                              <v-avatar v-if="row.product_image" size="40px">
                                <img max-height="40" max-width="40" :src="row.product_image" alt="John" />
                              </v-avatar>

                              {{ row.product_name }}</template>
                          </td>
                          <td style="max-width: 200px" @click="editRow($event, index)" class="py-1 px-2 text-truncate"
                            :class="row.isEditable ? 'px-2' : 'px-3'">
                            <TextInput v-if="row.isEditable" hide-details :disabled="isDisabled" :loading="pageLoading"
                              id="description" placeholder="" v-model="row.description">
                            </TextInput>
                            <template v-else class="px-1">{{ row.description }}</template>
                          </td>
                          <!-- {{ uomList}} -->
                          <td style="width: 200px" class="py-1 px-2" @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'">



                            <v-select v-if="row.isEditable" hide-details outlined item-color="cyan" :loading="pageLoading"
                              :items="row.uom_list ?? []" :rules="[vrules.required(row.uom, 'Supplier')]"
                              :class="{ required: !row.uom }" :disabled="isDisabled" @change="searchUom(row, index)"
                              id="uom" v-model="row.uom" placeholder="UOM">
                              <template v-slot:selection="{ item }">
                                <v-badge class="mt-4 me-5  mb-2" :color="item.color"></v-badge>
                                <v-list-item-content v-html="item.text"></v-list-item-content>
                              </template>
                              <template v-slot:item="data">
                                <template v-if="typeof data.item != 'object'">


                                  <v-list-item-content v-text="data.item"></v-list-item-content>


                                </template>
                                <template v-else>
                                  <v-badge class="mt-4 me-5 mb-2" :color="data.item.color"></v-badge>
                                  <v-list-item-content class="py-2">
                                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-select>
                            <template v-else class="px-1">{{ row.uom }}
                            </template>
                          </td>
                          <td class="py-1 px-2" @click="editRow($event, index)" :class="row.isEditable ? 'px-2' : 'px-3'">
                            <QuantityInput v-if="row.isEditable" hide-details :disabled="isDisabled" :loading="pageLoading"
                              id="quantity" type="number" placeholder=""
                              :rules="[vrules.required(row.quantity, 'Supplier')]" :class="{ required: !row.quantity }"
                              v-model="row.quantity" v-on:keyup="getProductTotal()">
                            </QuantityInput>
                            <template v-else class="px-1">{{ row.quantity }}</template>
                          </td>
                          <td class="py-1 px-2 text-right" @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'">
                            <QuantityInput v-if="row.isEditable" hide-details :disabled="isDisabled"
                              :loading="pageLoading" id="rate" type="number" v-model="row.rate"
                              :rules="[vrules.required(row.rate, 'Supplier')]" :class="{ required: !row.rate }"
                              v-on:keyup="getProductTotal()" fixedOnBlur
                              v-on:keydown.tab.prevent="tabClicked($event, index)">
                            </QuantityInput>
                            <template v-else class="px-1">{{ formatMoney(row.rate) }}</template>
                          </td>
                          <td v-if="discount_level == 'lineItems'" @click="editRow($event, index)"
                            class="py-1 px-2 text-right" :class="row.isEditable ? 'px-2' : 'px-3'">
                            <template class="px-1">{{ formatMoney(row.total) }}</template>
                          </td>
                          <td v-else @click="editRow($event, index)" class="py-1 px-2 text-right"
                            :class="row.isEditable ? 'px-2' : 'px-3'">
                            <template class="px-1">{{ formatMoney(row.total) }}</template>
                          </td>

                          <td v-if="discount_level == 'lineItems'" @click="editRow($event, index)"
                            class="py-1 px-2 text-right" :class="row.isEditable ? 'px-2' : 'px-3'">
                            <div class="d-flex align-items-center justify-content-centre">
                              <div style="width: 80px" class="">
                                <QuantityInput v-if="row.discount_values" hide-details :disabled="true"
                                  :loading="pageLoading" type="number" class="mt-0 " v-model="row.discounted_amount">
                             
                                </QuantityInput>
                              </div>
                              <div style="width: 80px" class="">

                                <QuantityInput hide-details :disabled="isDisabled" :loading="pageLoading" type="number"
                                  class="mt-0 " v-on:blur="getDiscounts(index)" v-on:change="getDiscounts(index)"
                                  v-on:keyup="getDiscounts(index)" v-model="row.discount_values">
                                </QuantityInput>
                              </div>
                              <div style="max-width: 80px" class="">
                                <!-- {{  discount_value_type}} -->
                                <SelectInput hide-details :disabled="pageLoading" :loading="pageLoading"
                                  :items.sync="discountTypeList" id="discount-type-list" class="mt-0 text-center"
                                  v-on:change="getDiscounts(index)" v-model="row.discount_value_type">
                                </SelectInput>
                              </div>
                            </div>
                          </td>
                          <td class="py-1 px-2 text-right">
                            <v-icon color="red" @click="deleteLineItems(index)"
                              :disabled="line_items.length == 1 ? true : false">mdi-delete-outline</v-icon>
                          </td>
                        </tr>
                      </Draggable>
                      <!-- </tbody> -->
                      <tfoot>
                        <tr>
                          <td class="pa-2 borer-none" colspan="4">
                            <v-btn @click="addLineItems()" class="white--text" depressed color="blue darken-4" tile>
                              <v-icon left>mdi-plus</v-icon>
                              Add Line item
                            </v-btn>
                          </td>
                          <td class="pa-2 borer-none text-right" colspan="3"><b>Sub Total</b></td>
                          <td v-if="discount_level !== 'lineItems'" class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(getSubTotal()) }}
                          </td>
                          <td v-else class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(getSubTotals()) }}
                          </td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr v-if="discount_level !== 'lineItems'">
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <b>Discount</b>
                              <div style="max-width: 80px" class="ml-2">
                                <QuantityInput hide-details :disabled="isDisabled" :loading="pageLoading" type="number"
                                  class="mt-0" v-on:blur="getDiscount()" v-on:change="getDiscount()"
                                  v-on:keyup="getDiscount()" v-model="discount_value">
                                </QuantityInput>

                                <!-- <QuantityInput
                                  hide-details
                                  :disabled="isDisabled"
                                  :loading="pageLoading"
                                  id="discount-value"
                                  type="number"
                                  class="mt-0"
                                  v-on:blur="getDiscount()"
                                  v-on:change="getDiscount()"
                                  v-model="discount_value"
                                >
                                </QuantityInput> -->
                              </div>
                              <div style="max-width: 80px" class="">
                                <SelectInput hide-details :disabled="pageLoading" :loading="pageLoading"
                                  :items.sync="discountTypeList" id="discount-type-list" class="mt-0 text-center"
                                  v-model="discount_value_type" v-on:change="getDiscount()">
                                </SelectInput>
                              </div>
                            </div>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(this.discount_amount) }}
                          </td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <v-checkbox hdie-details v-model="tax_applied" class="ma-0 pt-0" hide-details small
                                id="is-tax" v-on:change="getTax()" dense color="blue darken-4"></v-checkbox>
                              <b>Tax</b>
                              <div style="max-width: 60px" class="ml-2">
                                <span class="font-weight-bold" style="font-size: 16px">
                                  {{ tax_value }} %
                                </span>
                              </div>
                            </div>
                          </td>
                          <td v-if="discount_level !== 'lineItems'" class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(getTax()) }}
                          </td>
                          <td v-else class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(getTaxs()) }}
                          </td>

                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <b>Adjustment</b>
                              <div style="max-width: 350px" class="ml-2">
                                <!-- <QuantityInput
                                  hide-details
                                  :disabled="isDisabled"
                                  :loading="pageLoading"
                                  id="discount-value"
                                  type="number"
                                  class="mt-0"
                                  v-model="adjustment"
                                >
                                </QuantityInput> -->
                                <TextInput hide-details :disabled="isDisabled" :loading="pageLoading" fieldType="number"
                                  v-on:keyup="addadjustment($event)" v-on:keypress="addadjustment($event)"
                                  id="discount-value" :minMax="{ min: 1, max: 10000 }" style="max-width:
                                              350px" class="mt-0" v-model="adjustment"></TextInput>

                              </div>
                            </div>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">
                            {{ formatMoney(adjustment) }}
                          </td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <b style="font-size: 16px">Grand Total</b>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">
                            <b v-if="discount_level !== 'lineItems'" style="font-size: 16px">
                              {{ formatMoney(getGrandTotal()) }}</b>
                            <b v-else style="font-size: 16px">
                              {{ formatMoney(getGrandTotals()) }}</b>
                          </td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </v-col>
              <!-- Attachments & Other details -->
              <v-col md="12" class="blue lighten-5">
                <v-row>
                  <v-col md="12" class="my-auto py-0">
                    <p class="my-0 font-weight-bold">Attachments & Other details</p>
                    <span><v-icon color="disabled" size="18">mdi-help-circle-outline</v-icon> Specify
                      the Attachments & Other details for your Purchase order</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="9" class="pt-0">
                <v-row class="py-3">
                  <v-col md="2" class="my-4">
                    <label for="attachments" class="field-label">Attachments</label>
                  </v-col>
                  <v-col md="10">
                    <SupplierFile id="file" :directionColumn="false" :alignColumn="true" accept="image" allowAddMore
                      v-model="purchaseOrder.document"></SupplierFile>
                    <!-- <PurchaseDocument
                      id="file"
                      :directionColumn="false"
                      v-on:uploadData="test($event)"
                      allowAddMore
                      v-model="purchaseOrder.file"
                    ></PurchaseDocument> -->
                  </v-col>
                  <v-col md="2" class="">
                    <label for="remark" class="field-label">
                      Remark
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="disabled" size="18">mdi-help-circle-outline</v-icon>
                        </template>
                        <span>Remark Printed on pdf</span>
                      </v-tooltip>
                    </label>
                  </v-col>
                  <v-col md="10" class="">
                    <TextAreaInput v-model="purchaseOrder.remark" counter="250" auto-grow
                      v-on:keypress="(e) => manageLimit(e)" v-on:paste="(e) => onPaste(e)" :disabled="pageLoading" :rules="[
                        vrules.required(purchaseOrder.remark, 'Remark'),
                        (v) =>
                          (v && !hideDetails ? v.length <= 1024 : true) ||
                          `Remark maximum 250 characters`,
                      ]" :loading="pageLoading" id="remark" placeholder="Remark"></TextAreaInput>
                  </v-col>
                  <!-- <v-col md="2" class="my-auto">
                    <label for="terms-onditions" class="field-label"> Terms & Conditions </label>
                  </v-col>
                  <v-col md="10" class="py-0">
                    <SelectInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :items.sync="termsConditionList"
                      id="terms-onditions"
                      v-model="purchaseOrder.terms_conditions"
                      placeholder="Select Terms & Conditions"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageTermsDialog = true"
                    >
                    </SelectInput>
                  </v-col>
                  <v-col md="2" class="my-auto">
                    <label for="ref-number" class="field-label"> </label>
                  </v-col>
                  <v-col md="10" class="py-3">
                    <TextAreaInput
                      v-model="purchaseOrder.terms_conditions"
                      counter="1024"
                      auto-grow
                      @keypress="checkDoubleCurly($event, purchaseOrder.terms_conditions)"
                      :disabled="pageLoading"
                      :rules="[
                        vrules.required(purchaseOrder.terms_conditions, 'Terms & Conditions'),
                        (v) =>
                          (v && !hideDetails ? v.length <= 1024 : true) ||
                          `Terms & Conditions maximum 250 characters`,
                      ]"
                      :loading="pageLoading"
                      id="terms_conditions"
                      placeholder="Terms & Conditions"
                    ></TextAreaInput>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <Dialog :dialog="importDialog" card-text-class="px-4 py-0">
      <template v-slot:title> UPLOAD EXCEL FILE</template>
      <template v-slot:body>
        <v-container>
          <v-btn color="blue darken-4" depressed tile class="mr-4 white--text" v-on:click="downloadSample()">Download
            Sample</v-btn>
          <h4 class="mt-4">Download Sample For Line Items</h4>
          <v-layout class="my-4">
            <v-flex md2>
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-flex>
            <v-flex md10>
              <v-file-input v-model="excelFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref="fileInput" hide-details id="item-file-upload" label="Excel File" outlined dense></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn class="white--text mr-2" depressed color="blue darken-4" tile :disabled="excelLoading"
          :loading="excelLoading" v-on:click="uploadExcel()">
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="excelLoading" depressed tile v-on:click="importDialog = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="manageTermsDialog" card-text-class="px-4 py-0">
      <template v-slot:title>
        <span>MANAGE TERMS & CONDITIONS</span> <v-spacer></v-spacer><v-btn @click="addTerms()" color="blue darken-4"
          depressed tile class="mr-4 white--text"><v-icon>mdi-plus</v-icon>Add</v-btn></template>
      <template v-slot:body>
        <v-container>
          <template v-for="(item, index) in manageTermsList">
            <v-layout class="bg_grey pt-4 pb-2 px-3 my-2" :key="index.id">
              <v-flex md11>
                <TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="terms-condition"
                  placeholder="Terms & Conditions" v-model="item.terms_condition" class="mb-2 required">
                </TextInput>
                <TextAreaInput v-model="item.description" auto-grow :disabled="pageLoading" :loading="pageLoading"
                  id="description" placeholder="Description"></TextAreaInput>
              </v-flex>
              <v-flex md1 class="my-auto">
                <label for="item-file-upload" class="btx-label">
                  <!-- <v-btn icon="mdi-delete" fab dark small tile class="me-4 white--red"></v-btn> -->
                  <v-btn class="ms-4" icon color="red" @click="removeTerms(index)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </label>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn class="white--text mr-2" depressed color="blue darken-4" tile :disabled="excelLoading"
          :loading="excelLoading" v-on:click="uploadExcel()">
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="excelLoading" depressed tile v-on:click="manageTermsDialog = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <ProductCreateDialog
			v-if="createProductDialog"
			:dialog="createProductDialog"
      :supplierLists="supplierList"
			:supplier-id="first_name"
			v-on:close="createProductDialog = false"
			v-on:success="selectSupplier12(purchaseOrder.supplier);"
		></ProductCreateDialog>
  </v-sheet>
</template>

<script>
import { toSafeInteger, map, cloneDeep, filter } from "lodash";
import Draggable from "vuedraggable";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import QuantityInput from "@/view/components/QuantityInput";
//import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
//import POLineItems from "@/view/components/POLineItems";
import ProductCreateDialog from "@/view/components/ProductCreateDialog";

import MomentJS from "moment-timezone";
// import NumberInput from "@/view/components/NumberInput";

import { formatMoney } from "accounting-js";
// import PriceInput from "@/view/components/PriceInput";
import SupplierFile from "@/view/components/SupplierFile";

import { POST, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_PO, FIND_PO, UPDATE_PO } from "@/core/lib/pos.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import FileManagerMixin from "@/core/mixins/file-manager/file.manager.mixin";

import { find } from "lodash";

export default {
  name: "Purchase-order-create",
  title: "Create Purchase Order",
  mixins: [FileManagerMixin],
  data() {
    return {
      discount_level: "transaction",
      product_supplierss: [],
      supllier_name:null,
      searchButton: true,
      createProductDialog:false,
      tax_applied: 0,
      isDisabled: true,
      pageLoading: false,
      first_name:null,
      date_bill: MomentJS().format("YYYY-MM-DD"),

      total: 0,
      formValid: false,
      filterProduct: null,
      productId: null,
      excelFile: null,
      excelLoading: null,
      search_line_item: null,
      document: 0,
      vAttachments: [{ file: null, name: null, ext: null }],
      line_items: [
        {
          isEditable: false,
          product: null,
          description: null,
          quantity: 1,
          order: 1,
          uom: null,
          rate: 0,
          total: 0,
          product_name: null,
          product_image: null,
          discount_values: 0,
          discount_value_type: 1,
          discount_amount: 0,
        },
      ],
      taxList: [9],
      tax_value: 9,
      discount_value: 0,
      // discount_values: 0,
      discount_amount: 0,
      discount_value_type: 1,
      adjustment: 0,
      adjustment_amount: 0,
      discountTypeList: [
        { text: "RM", value: 1 },
        { text: "%", value: 2 },
      ],
      // productList: [],
      // allProductList: [],

      supplierList: [
        // {
        //   text: "S00001 - Genic Solution",
        //   value: "genic-solution",
        //   avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        // },
        // {
        //   text: "S00002 - Bt Solution",
        //   value: "bt-solution",
        //   avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        // },
      ],
      receivingList: [
        {
          text: "Sutra",
          value: "Sutra",
        },
      ],
      uomList: [
        // {
        //   text: "Can",
        //   value: "can",
        //   color: "blue",
        // },
        // {
        //   text: "Ctn",
        //   value: "ctn",
        //   color: "green",
        // },
      ],
      productList: [
        // {
        //   text: "P010 - screw",
        //   value: "screw",
        //   avatar:
        //     "https://static.fnac-static.com/multimedia/Images/FD/Comete/80444/CCP_IMG_ORIGINAL/1008963.jpg",
        // },
        // {
        //   text: "P003 - iphone",
        //   value: "iphone",
        //   avatar: "https://th.bing.com/th/id/OIP.zK1Wp6Zd0K9M3cJIhKFkOQHaHa?pid=ImgDet&rs=1",
        // },
      ],
      termsConditionList: [
        {
          text: "Purchasing order next",
          value: "purchasing order next",
        },
        {
          text: "Purchasing order terms",
          value: "purchasing order terms",
        },
      ],

      // supplier_form
      manageTermsList: [
        {
          terms_condition: "Purchasing order next",
          description:
            "deliverable or other product or result from Shervices that is referred to in a Purchase Order.",
        },
        {
          terms_condition: "",
          description: "",
        },
      ],
      received_status: [1],
      images: [],
      importDialog: false,
      manageTermsDialog: false,
      purchaseOrder: {
        refNumber: null,
        delivery_date: MomentJS().format("YYYY-MM-DD"),
        supplier: null,
        suppliers: null,
        receivingAdd: "Sutra",
        supplier_product: false,
        termsConditionList: null,
        terms_conditions: null,
        remark: null,
        process:null,
        show_only_supplier_products: 1,
        files: [],
        document: []
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    TextAreaInput,
    Dialog,
    Draggable,
    // NumberInput,
    // PriceInput,
    //POLineItems,
    QuantityInput,
    ProductCreateDialog,

    // PurchaseDocument,
    DatePicker,
    SupplierFile
  },
  computed: {
    productListsss: () => {

      return function (id) {
        let _vendorList = cloneDeep(this.productList);
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {

            if (!find(this.line_items, (inner_row) => inner_row.product == row.value)) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };


    },
    getSubTotal: () => {
      return function () {
        let sum = 0;
        this.line_items.forEach((element) => {
          sum = sum + Number(element.total);
        });
        this.sub_total = Number(sum);
        // if(this.$route.params.id){
        this.getDiscount1();

        // }
        return this.sub_total;
      };
    },
    getSubTotals: () => {

      return function () {
        let sum = 0;
         this.line_items.forEach((element) => {
          sum = sum + Number(element.total) - Number(element.discount_amount);
        });
        this.sub_total = Number(sum);
        return this.sub_total;
      };
    },
  },

  methods: {
    openCreateProduct() {
      if(this.purchaseOrder?.supplier){
        this.createProductDialog = true;

      }
			//this.index = index;
		},
    selectProduct() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `/select-product`,
        })
        .then((data) => {
          console.log(data, 'data')
          _this.productLists = data.products;
          console.log(_this.productLists, '_this.productLists')
          _this.isDisabled = false;
          const productList1 = _this.productLists.map((item) => {
            return {
              text: item.barcode + " - " + item.name_en,
              value: item.id,
              name: item.name_en,
              avatar: item.profile_logo?.file?.url,
            };
          });
          _this.productList = productList1;
          _this.discount_value = 0;
          _this.adjustment = 0;
          // _this.line_items = [
          //   {
          //     isEditable: false,
          //     product: null,
          //     description: null,
          //     quantity: 1,
          //     order: 1,
          //     uom: null,
          //     rate: 0,
          //     total: 0,
          //     product_name: null,
          //     product_image: null,
          //     discount_values: 0,
          //     discount_value_type: 1,
          //     discount_amount: 0,
          //   },
          // ];

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
         console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.searchButton = false;
        });
    },
    selectProducts(result, value) {
      console.log(value)
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `/select-product`,
        })
        .then((data) => {
          console.log(data, 'data')
          _this.productLists = data.products;
          console.log(_this.productLists, '_this.productLists')
          _this.isDisabled = false;
          const productList1 = _this.productLists.map((item) => {
            return {
              text: item.barcode + " - " + item.name_en,
              value: item.id,
              name: item.name_en,
              avatar: item.profile_logo?.file?.url,
            };
          });
          _this.productList = productList1;
          _this.discount_value = 0;
          _this.adjustment = 0;
          _this.line_items=result;
         
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.searchButton = false;
        });
    },

    showSupplierProductChange(e) {
      console.log(e, 'e')
      if (this.purchaseOrder.supplier) {
        if (e == 0) {
          this.selectProduct();
          console.log(this.purchaseOrder.supplier, 'supplier')

        } else {
          this.selectSupplier12(this.purchaseOrder.supplier);
        }
      }

    },
    // comparedate(){
    //   console.log(this.purchaseOrder.delivery_date,'purchaseOrder.delivery_date')

    // },
    addadjustment(event) {
      console.log(event, 'event')

      if (this.adjustment.length > 5) {
        // return false
        event.preventDefault();
        return false;



      } else {
        if (Number(this.total) < 0) {
          this.adjustment = 0;
        }


      }


    },
    manageLimit(e) {
      if (this.purchaseOrder.remark &&
        this.purchaseOrder.remark.length > 249) {
        e.preventDefault();
      }

    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.purchaseOrder.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 249) {
        let trimValue = finalval.substring(0, 250);
        this.purchaseOrder.remark = trimValue;
        e.preventDefault();
      }
    },
    // uploadFiles(file) {
    //   console.log(file)
    //   if (file[0] && file[0]?.type) {
    //     console.log(file[0]?.type)
    //     let fileExtention = file[0]?.type.split("/");
    //     fileExtention = fileExtention[0];
    //     if (fileExtention != "image") {
    //       ErrorEventBus.$emit(
    //         "update:error",
    //         InitializeError("Only image allowed")
    //       );
    //       return false;
    //     }
    //   }

    //   this.isUploading = true;
    //   // const customer =  UPLOAD_FILE(file[0]);
    //   if (file[0] && file[0]?.type) {
    //     this.$store
    //     .dispatch(POST, {
    //       url: `upload-image`,
    //       data: file[0]
    //     })
    //     .then((data) => {
    //       this.document = data.id;
    //       console.log()
    //       // this.document = { ...this.document, id: 'k'+this.documents.length + 1 };
    //       if(data.id){
    //         this.documents.push(data.id);
    //       console.log(this.documents)
    //       }

    //     })
    //     .catch((error) => {
    //       this.logError(error);
    //     })
    //     .finally(() => {
    //       this.timerLoading = false;
    //     });
    //   }
    //   // this.document.push();
    //   // this.uploadAttachment(file[0])
    //   //   .then((response) => {
    //   //     for (let i = response.length - 1; i >= 0; i--) {
    //   //       this.documentArray.push(response[i]);
    //   //     }
    //   //   })
    //   //   .catch((error) => {
    //   //     this.logError(error);
    //   //   })
    //   //   .finally(() => {
    //   //     this.isUploading = false;
    //   //   });
    // },

    fixedOnBlur() {
      const _line_item = map(this.line_items, (row) => {
        return {
          ...row,
          rate: Number(row.rate),
          total: Number(row.total),
        };
      });
      this.line_items = _line_item;
    },
    tabClicked(event, index) {
      /* console.log({ index: index, event: event }); */
      if (this.line_items.length == index + 1) {
        this.addLineItems();
      }

      const _line_items = this.line_items.map((row) => {
        return { ...row, isEditable: false };
      });
      this.line_items = _line_items;
      this.line_items[index + 1].isEditable = true;
      this.$nextTick(() => {
        // const inputarea = element.querySelector("input");
        // if (inputarea) {
        // 	inputarea.focus();
        // 	inputarea.select();
        // }
      });
    },
    test(data) {
      this.images = data;
      console.log(this.images, 'images')
    },
    getProduct() {
      const _this = this;
      this.previous = this.line_items;
      console.log(this.line_items)
      if (!this.search_line_item) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: 'The search field is required' }]);
        return false;

      }
      if(this.purchaseOrder.show_only_supplier_products == 1){
        this.supllier_name = this.purchaseOrder?.suppliers?.first_name;
      }
      else{
        this.supllier_name = null;

      }
      console.log(_this.search_line_item, '_this.search_line_item')
      this.$store
        .dispatch(POST, {
          url: `search-product`,
          data: {
            search: this.search_line_item,
            selected_supplier: this.supllier_name,
          },
        })
        .then((data) => {
          _this.line_itemssss = data.data;

          const supplierList1 = _this.line_itemssss.map((item) => {
            return {
              "description": null,
              "discount_amount": 0,
              "discount_value_type": 1,
              "discount_values": 0,
              "query":null,
              "isEditable": true,
              "order": 1,
              "product": item.product,
              "product_image": item.product_image,
              "product_name": item.product_name,
              "quantity": 1,
              "uom": item.uom,
              "rate": item.rate,
              "total": item.total,
              "uom_list": [
                {
                  text: item.primary,
                  value: item.primary,
                  color: "blue",
                },
                {
                  text: item.secondary,
                  value: item.secondary,
                  color: "red",
                },
              ]
            };
          });
          
          _this.line_items =  [..._this.line_items,...supplierList1];
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },

    searchUom(row, index) {
      this.filterProductss = find(this.productLists, {
        id: this.line_items[index]?.product,
      });

      //  console.log(this.filterProductss,'uom')

      if (this.line_items[index]?.product) {
        if (this.line_items[index]?.uom == this.filterProductss?.secondary_purchase_uom) {
          this.filterProduct = find(this.productLists, {
            secondary_purchase_uom: this.line_items[index]?.uom,
            id: this.line_items[index]?.product,
          });
          this.filterSupplier = find(this.filterProduct.supplier, {
        first_name: this.purchaseOrder?.suppliers?.first_name,
      });
      if(this.filterSupplier){
       this.line_items[index].rate = this.filterSupplier?.secondary_purchase_price;
       this.line_items[index].total = this.filterSupplier?.secondary_purchase_price;

      
      }else{
        this.line_items[index].rate = 0;
        this.line_items[index].total = 0;


      }
          this.line_items[index].uom = this.filterProduct?.secondary_purchase_uom;
          // this.line_items[index].rate = this.filterProduct?.supplier[0]?.secondary_purchase_price;
          // this.line_items[index].total = this.filterProduct?.supplier[0]?.secondary_purchase_price;
          this.getProductTotal();
        } else if (this.line_items[index]?.uom == this.filterProductss?.primary_sales_uom) {
          this.filterProduct = find(this.productLists, {
            primary_sales_uom: this.line_items[index]?.uom,
            id: this.line_items[index]?.product,
          });
          this.filterSupplier = find(this.filterProduct.supplier, {
        first_name: this.purchaseOrder?.suppliers?.first_name,
      });
      if(this.filterSupplier){
       this.line_items[index].rate = this.filterSupplier?.primary_sales_price;
       this.line_items[index].total = this.filterSupplier?.primary_sales_price;

      
      }else{
        this.line_items[index].rate = 0;
        this.line_items[index].total = 0;


      }
          this.line_items[index].uom = this.filterProduct?.primary_sales_uom;
          // this.line_items[index].rate = this.filterProduct?.supplier[0]?.primary_sales_price;
          // this.line_items[index].total = this.filterProduct?.supplier[0]?.primary_sales_price;
          this.getProductTotal();
        } else {
          this.line_items[index].uom = this.filterProduct?.primary_sales_uom;
          this.line_items[index].rate = 0;
          this.line_items[index].total = 0;
          this.getProductTotal();
        }
      }
    },
    // getData(event){
    //   alert(1)
    //   console.log(event)

    // },
    getGrandTotal() {
      this.total =
        Number(this.sub_total) +
        Number(this.tax_amount) +
        Number(this.adjustment) -
        Number(this.discount_amount);
      // this.updateCalculation();
      return this.total;
    },
    getGrandTotals() {
      this.total = Number(this.sub_total) + Number(this.tax_amount) + Number(this.adjustment);
      // Number(this.adjustment) -

      // this.updateCalculation();
      return this.total;
    },
    getTax() {
      if (this.tax_applied) {
        const _taxable_amount = Number(this.sub_total) - Number(this.discount_amount);
        this.tax_amount = Number((_taxable_amount * this.tax_value) / 100);
        this.taxable_amount = _taxable_amount;
      } else {
        this.tax_amount = 0;
      }
      return this.tax_amount;
    },
    getTaxs() {
      if (this.tax_applied) {
        const _taxable_amount = Number(this.sub_total);
        this.tax_amount = Number((_taxable_amount * this.tax_value) / 100);
        this.taxable_amount = _taxable_amount;
      } else {
        this.tax_amount = 0;
      }
      return this.tax_amount;
    },
    validateNumber(value) {
      const pattern = /^(\d+(?:,\d{1,2})?).*/;
      return pattern.test(value);
    },
    getDiscount() {



      if (this.discount_value != 0) {
        if (!this.validateNumber(this.discount_value)) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
      }
      const subtotal = this.sub_total;

      if (this.discount_value_type == 1) {
        //$
        if (Number(this.discount_value) >= Number(subtotal)) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
        this.discount_amount = Number(this.discount_value);
      } else if (this.discount_value_type == 2) {
        //%
        if (Number(this.discount_value) >= 100) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
        this.discount_amount = Number((subtotal * this.discount_value) / 100);
      }
      return this.discount_amount;
    },
    getDiscount1() {



      if (this.discount_value != 0) {
        if (!this.validateNumber(this.discount_value)) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
      }
      const subtotal = this.sub_total;

      if (this.discount_value_type == 1) {
        //$
        if (Number(this.discount_value) >= Number(subtotal)) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
        this.discount_amount = Number(this.discount_value);
      } else if (this.discount_value_type == 2) {
        //%
        if (Number(this.discount_value) >= 100) {
          this.discount_value = 0;
          this.discount_amount = 0;
          return false;
        }
        this.discount_amount = Number((subtotal * this.discount_value) / 100);
      }
      return this.discount_amount;
    },
    getDiscounts(index) {
      console.log(this.line_items[index],'this.line_items[index]')

if (this.line_items && this.line_items[index]?.discount_value_type == 1) {
  //$
  if (Number(this.line_items[index].discount_values) > Number(this.line_items[index].total)) {
    this.line_items[index].discount_values = 0;
    this.line_items[index].discount_amount = 0;
    return false;
  }
  this.line_items[index].discount_amount = Number(this.line_items[index].discount_values);
  if (Number(this.line_items[index].discount_values) != 0) {
    this.line_items[index].discounted_amount = Number(this.line_items[index].total) - Number(this.line_items[index].discount_amount);
  } else {
    this.line_items[index].discounted_amount = 0;
  }



} else if (this.line_items[index]?.discount_value_type == 2) {
  //%
  if (Number(this.line_items[index].discount_values) > 100) {
    this.line_items[index].discount_values = 0;
    this.line_items[index].discount_amount = 0;
    return false;
  }
  this.line_items[index].discount_amount = Number(
    ((this.line_items[index].rate*this.line_items[index].quantity) * (this.line_items[index].discount_values / 100)) 
  );

  
  if (Number(this.line_items[index].discount_amount) != 0) {
    this.line_items[index].discounted_amount = Number(this.line_items[index].rate*this.line_items[index].quantity) - Number(this.line_items[index].discount_amount);
  } else {
    this.line_items[index].discounted_amount = 0;
  }
}
},
    // getDiscounts(index) {

    //   if (this.line_items && this.line_items[index]?.discount_value_type == 1) {
    //     //$
    //     if (Number(this.line_items[index].discount_values) > Number(this.line_items[index].total)) {
    //       this.line_items[index].discount_values = 0;
    //       this.line_items[index].discount_amount = 0;
    //       return false;
    //     }
    //     this.line_items[index].discount_amount = Number(this.line_items[index].discount_values);
    //     if (Number(this.line_items[index].discount_values) != 0) {
    //       this.line_items[index].discounted_amount = Number(this.line_items[index].total) - Number(this.line_items[index].discount_amount);
    //     } else {
    //       this.line_items[index].discounted_amount = 0;
    //     }

    //     console.log(this.line_items[index].discounted_amount, 'this.line_items[index].discounted_amount')


    //   } else if (this.line_items[index]?.discount_value_type == 2) {
    //     //%
    //     if (Number(this.line_items[index].discount_values) > 100) {
    //       this.line_items[index].discount_values = 0;
    //       this.line_items[index].discount_amount = 0;
    //       return false;
    //     }
    //     this.line_items[index].discount_amount = Number(
    //       (this.line_items[index].total * this.line_items[index].discount_values) / 100
    //     );
    //     alert(2)
    //     console.log(this.line_items[index].discount_amount,'fsfsfsfsdfsfsf')
    //     if (Number(this.line_items[index].discount_amount) != 0) {
    //       this.line_items[index].discounted_amount = Number(this.line_items[index].total) - Number(this.line_items[index].discount_amount);
    //     } else {
    //       this.line_items[index].discounted_amount = 0;
    //     }
    //   }
    // },

    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    getProductTotal() {
      
      const _line_item = map(this.line_items, (row,index) => {
        if(row.discount_value_type ==2){
              this.getDiscounts(index);
       
        if (!row.product) {
          return {
            ...row,

            total: 0,
          };
        } else {
          return {
            ...row,

            total: row.quantity * row.rate,
          };
        }
      }else{
        if (!row.product) {
          return {
            ...row,

            total: 0,
          };
        } else {
          return {
            ...row,

            total: row.quantity * row.rate,
          };
        }

      }

      });
      // console.log(this.line_items)
      
       this.getDiscount();
      
      this.line_items = _line_item;
    },
    searchProduct(row, index) {
      console.log(this.purchaseOrder?.suppliers,'fdsfsf')
      
      this.filterProduct = find(this.productLists, {
        id: row.product,
      });
      
      // console.log(this.filterProduct,'filterProduct')

      this.line_items[index].uom = this.filterProduct?.primary_sales_uom;
      // let supplier_name=this.purchaseOrder.suppliers.first_name;
      
      this.filterSupplier = find(this.filterProduct.supplier, {
        first_name: this.purchaseOrder?.suppliers?.first_name,
      });
      if(this.filterSupplier){
       this.line_items[index].rate = this.filterSupplier?.primary_sales_price;
       this.line_items[index].total = this.filterSupplier?.primary_sales_price;

      
      }else{
        this.line_items[index].rate = 0;
        this.line_items[index].total = 0;


      }
      // if(this.purchaseOrder.suppliers.first_name == this.filterProduct?.supplier[0]?.first_name){
      //   this.line_items[index].rate = this.filterProduct?.supplier[0]?.primary_sales_price;

      // }else{
      //   this.line_items[index].rate = 0;

      // }
      // this.line_items[index].total = this.filterProduct?.supplier[0]?.primary_sales_price;
      this.line_items[index].product_name = this.filterProduct?.barcode + " - " + this.filterProduct?.name_en;
      this.line_items[index].product_image = this.filterProduct?.profile_logo?.file?.url;


      this.line_items[index].uom_list = [
        {
          text: this.filterProduct?.primary_sales_uom,
          value: this.filterProduct?.primary_sales_uom,
          color: "blue",
        },
        {
          text: this.filterProduct?.secondary_purchase_uom,
          value: this.filterProduct?.secondary_purchase_uom,
          color: "red",
        },
      ];
    },
    // selectUoms() {
    //   const _this = this;
    //   this.$store
    //     .dispatch(QUERY, {
    //       url: `uoms`,
    //     })
    //     .then((data) => {
    //       _this.uomList = data;

    //       /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
    //      console.log(_this.purchaseOrder.supplier.barcode)     */
    //     })
    //     .catch((error) => {
    //       this.logError(error);
    //     })
    //     .finally(() => {
    //       this.timerLoading = false;
    //     });
    // },

    // filter_category(param) {
    //     this.filterProduct = filter(this.departments, {
    //       parent: param,
    //     });
    //     console.log(this.filterDepartments, "this.filterDepartments");
    //   },
    selectSupplier12(id) {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `/select-supplier/${id}`,
        })
        .then((data) => {
          _this.purchaseOrder.supplier = data.supplier.id;
          _this.purchaseOrder.suppliers = data.supplier;
          _this.productLists = data.product;
          _this.isDisabled = false;
          const productList1 = _this.productLists.map((item) => {
            return {
              text: item.barcode + " - " + item.name_en,
              value: item.id,
              name: item.name_en,
              avatar: item.profile_logo?.file?.url,
            };
          });
          _this.productList = productList1;
          _this.discount_value = 0;
          _this.adjustment = 0;
          // _this.line_items = [
          //   {
          //     isEditable: false,
          //     product: null,
          //     description: null,
          //     quantity: 1,
          //     order: 1,
          //     uom: null,
          //     rate: 0,
          //     total: 0,
          //     product_name: null,
          //     product_image: null,
          //     discount_values: 0,
          //     discount_value_type: 1,
          //     discount_amount: 0,
          //   },
          // ];

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
         console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.searchButton = false;
          this.purchaseOrder.show_only_supplier_products=1;
        });
    },
    selectSupplier(id) {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `/select-supplier/${id}`,
        })
        .then((data) => {
          _this.purchaseOrder.supplier = data.supplier.id;
          _this.purchaseOrder.suppliers = data.supplier;
          _this.first_name = data.supplier.first_name;

          console.log(this.first_name,'suppliers')
          _this.productLists = data.product;
          _this.isDisabled = false;
          const productList1 = _this.productLists.map((item) => {
            return {
              text: item.barcode + " - " + item.name_en,
              value: item.id,
              name: item.name_en,
              avatar: item.profile_logo?.file?.url,
            };
          });
          _this.productList = productList1;
          _this.discount_value = 0;
          _this.adjustment = 0;
          _this.line_items = [
            {
              isEditable: false,
              product: null,
              description: null,
              quantity: 1,
              order: 1,
              uom: null,
              rate: 0,
              total: 0,
              product_name: null,
              product_image: null,
              discount_values: 0,
              discount_value_type: 1,
              discount_amount: 0,
            },
          ];

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
         console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.searchButton = false;
          this.purchaseOrder.show_only_supplier_products=1;
        });
    },
    selectSuppliers(id, result, value) {
      // console.log(result,"dfdff")

      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `/select-supplier/${id}`,
        })
        .then((data) => {
          _this.purchaseOrder.supplier = data.supplier.id;
          _this.purchaseOrder.suppliers = data.supplier;
          _this.productLists = data.product;
          // _this.isDisabled = false;
          const productList1 = _this.productLists.map((item) => {
            return {
              text: item.barcode + " - " + item.name_en,
              value: item.id,
              avatar: item.profile_logo?.file?.url,
            };
          });
          _this.productList = productList1;
          _this.discount_value = value;
          //  _this.adjustment=0
          _this.line_items = result;

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
         console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getSupplier() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: "supplier-purchase/list",
        })
        .then((data) => {
          _this.supplierLists = data.rows;
          const supplierList1 = _this.supplierLists.map((item) => {
            return {
              barcode: item.barcode + " - " + item.company_name,
              image: item.supplier_image?.url,
              value: item.id,
              name: item.first_name,
            };
          });
          _this.supplierList = supplierList1;
          // console.log(_this.supplierList)
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },

    editRow(event, index) {
      this.isOutside = false;
      const element = event.target;
      //console.log(element.nodeName);
      const _line_items = this.line_items.map((row) => {
        return { ...row, isEditable: false };
      });
      this.line_items = _line_items;
      this.line_items[index].isEditable = true;
      this.$nextTick(() => {
        const inputarea = element.querySelector("input");
        if (inputarea) {
          inputarea.focus();
          inputarea.select();
        }
      });
      if (element.nodeName == "INPUT") {
        element.select();
      }
      if (this.line_items.length == index + 1) {
        this.addLineItems();
      }
    },
    deleteLineItems(index) {
      this.line_items.splice(index, 1);
    },
    addLineItems() {
      const _order = this.line_items.length;

      this.line_items.push({
        isEditable: false,
        id: null,
        uuid: null,
        product: null,
        description: null,
        uom: null,
        quantity: 1,
        discount_values: 0,
        discountTypeList: 1,
        discount_amount: 0,
        discount_value_type: 1,
        discounted_amount: 0,
        rate: 0,
        total: 0,
        order: _order + 1,
      });
      this.$emit("input", this.line_items);
    },
    addTerms() {
      this.manageTermsList.push({
        terms_condition: null,
        description: null,
      });
    },
    removeTerms(index) {
      this.manageTermsList.splice(index, 1);
    },
    pageTitle() {
      if (this.$route.params.id) {
        return "Update Purchase Order";
      }
      return "Create New Purchase Order";
    },
    async updateOrCreate(query) {
      const _this = this;
      _this.process = query;
      _this.po_id = this.$route.params.id;
      _this.purchaseOrder.discount_level = _this.discount_level;
      _this.purchaseOrder.discount_amount = _this.discount_amount;
      _this.purchaseOrder.discount_value = _this.discount_value;
      _this.purchaseOrder.process = _this.process;

      _this.purchaseOrder.discount_value_type = _this.discount_value_type;
      _this.purchaseOrder.tax_applied = _this.tax_applied;
      _this.purchaseOrder.tax_value = _this.tax_value;
      _this.purchaseOrder.adjustment = _this.adjustment;
      _this.purchaseOrder.received_status = 1;
      _this.purchaseOrder.attachements = this.images && this.images.length > 0 ? this.images : [];
      // _this.filterItem = filter(this.line_items, {
      //   product:null,
      //    });
      const order_length = _this.line_items.length;
      const items = map(_this.line_items, (row) => {
        return {
          id: row.id ? row.id : null,
          uuid: row.uuid ? row.uuid : null,
          product: row.product ? row.product : null,
          product_name: row.product_name ? row.product_name : null,
          product_image: row.product_image ? row.product_image : null,
          description: row.description ? row.description : null,
          quantity: row.quantity ? Number(row.quantity) : 0,
          rate: row.rate ? Number(row.rate) : 0,
          uom: row.uom ? row.uom : null,
          discount_values: row.discount_values ? row.discount_values : 0,
          discount_value_type: row.discount_value_type ? row.discount_value_type : 1,
          discount_amount: row.discount_amount ? Number(row.discount_amount) : 0,
          order: row.order ? Number(row.order) : order_length + 1,
        };
      }).filter((row) => row.product != null);
      // console.log(items)
      _this.purchaseOrder.items = items;
      if (!_this.$refs.productAddForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.po_id) {
          const customer = await UPDATE_PO(_this.po_id, _this.purchaseOrder);
          console.log(customer)

          _this.$router.replace({
            name: "purchase-order",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Purchase Order Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_PO(_this.purchaseOrder);
          console.log(customer)


          _this.$router.replace({
            name: "purchase-order",
            // params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Purchase Order Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getPo() {
      this.uuid = this.$route.params.id;
      this.isDisabled = false;
      const customer = await FIND_PO(this.uuid);
      this.purchaseOrder = {
        refNumber: customer.refNumber,
        show_only_supplier_products: customer.show_only_supplier_products,
        remark: customer.remarks,
        delivery_date: customer.delivery_date,
        supplier: customer.supplier_id,
        barcode: customer.barcode,
        receivingAdd: customer.receivingAdd,
        discount_value_type: customer.discount_value_type,
        discount_level: customer.discount_level,
        discount_value: customer.discount_amount,
        discount_amount: customer.total_discount,
        tax_applied: customer.tax_applied,
        adjustment: customer.adjustment,
        
      };
      this.first_name=customer.supplier.first_name;
      
      console.log(this.first_name,'fsdf')
      this.tax_applied = this.purchaseOrder.tax_applied;

      this.adjustment = this.purchaseOrder.adjustment;
      this.discount_value = this.purchaseOrder.discount_value;



      this.discount_value_type = this.purchaseOrder.discount_value_type;
      if (this.purchaseOrder.discount_level == "lineItems") {
        this.discount_amount = 0;
      }
      else {
        this.discount_amount = this.purchaseOrder.discount_amount;
      }
      this.discount_level = this.purchaseOrder.discount_level;


      this.line_itemss = customer.order_items;

      const result = customer.order_items.map((item) => {
        return {
          product_image: item?.product?.transaction_documents?.file?.url,
          product: item?.product?.id,
          product_name: item?.product?.name_en + " - " + item?.product?.barcode,
          rate: item?.rate,
          id: item?.id,
          quantity: item?.quantity,
          total: item?.quantity * item?.rate,
          uom: item?.uom,
          discount_value_type: item?.discount_value_type,
          discount_values: item?.discount_value,

          description: item?.description,
          discount_amount: item?.discount_amount,
          discounted_amount: Number(item?.quantity * item?.rate) - Number(item?.discount_amount),
          uom_list: [
            {
              text: item.product?.primary_sales_uom,
              value: item.product?.primary_sales_uom,
              color: "blue",
            },
            {
              text: item.product?.secondary_purchase_uom,
              value: item.product?.secondary_purchase_uom,
              color: "red",
            },
          ],
        };
      });
      // this.uomList=result;
      // this.line_items = result;
      if(this.purchaseOrder.show_only_supplier_products == 1){
        this.selectSuppliers(this.purchaseOrder?.supplier, result, this.discount_value);
      }else{
        this.selectProducts(result, this.discount_value);

      }

      this.productLists = this.line_items;
      // _this.isDisabled = false;
      //   const productList1 =  this.productLists.map((item) => {
      //    return {
      //     text: item?.product_name,
      //     value:item?.product,
      //     avatar:item?.product_image

      //    };
      //  });
      //  this.productList = productList1;
      // console.log(result,"result")

      // this.uomList = [
      //   {
      //     text: this.filterProduct?.primary_sales_uom,
      //     value: this.filterProduct?.primary_sales_uom,
      //   },
      //   {
      //     text: this.filterProduct?.secondary_purchase_uom,
      //     value: this.filterProduct?.secondary_purchase_uom,
      //   },
      // ];
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
    addSupplier() {
      const supplier = { ...this.supplier, id: this.purchaseOrder.suppliers.length + 1 };
      this.purchaseOrder.suppliers.push(supplier);
    },

    removeSupplier(index) {
      this.purchaseOrder.suppliers.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);
    // this.selectUoms();
    this.getSupplier();
    const { name, params } = this.$route;
    if (name === "purchase-order-update") {
      const { id } = params;
      if (id) {
        this.productId = toSafeInteger(id);
        this.searchButton = false;
        this.getPo();
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style scoped>
.v-input {
  margin-top: 0% !important;
}

.field-label {
  font-size: 1rem;
  font-weight: 500;
}

.left-idf-primary {
  border-left: 10px solid #5cb85c;
  border-top: 1px solid #5cb85c;
  border-bottom: 1px solid #5cb85c;
  border-right: 1px solid #5cb85c;
}

.idf_mark_primary {
  background-color: #5cb85c;
}

.idf_mark_secondary {
  background-color: #e64a4a;
}

.left-idf-secondary {
  border-left: 10px solid #e64a4a;
  border-top: 1px solid #e64a4a;
  border-bottom: 1px solid #e64a4a;
  border-right: 1px solid #e64a4a;
}

.bg_grey:hover {
  background-color: #f5f5f5 !important;
}

.po-line-items th {
  font-weight: 500;
}

.po-line-items th,
.po-line-items td:not(.borer-none) {
  border-bottom: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  height: 40px;
}

.po-line-items th:first-child,
.po-line-items td:first-child {
  border-left: none !important;
}

button.v-btn.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
  background-color: rgb(34, 167, 93) !important;
  color: #fff;
}

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
  background-color: rgb(196, 47, 34) !important;
  color: #fff;
}
</style>
