<template>
	<div>

		<Dialog :dialog="dialog" v-on:close="$emit('close', true)" :dialog-width="1000">
			<!-- {{ purchaseOrder?.suppliers?.first_name }} -->
			<template v-slot:title> Create New Product </template>
			<template v-slot:body>
				<!-- {{ supplierId }} -->
				<v-form ref="inventoryForm" v-model.trim="formValid" lazy-validation
					v-on:submit.stop.prevent="updateOrCreate">
					<v-row>
						<!-- <v-col md="3" class="my-auto py-0">
							<label for="name" class="btx-label fw-500 mt-2">Product Type</label>
						</v-col>
						<v-col md="9" class="py-0">
							<v-radio-group
								v-on:change="getSerialNumber()"
								class="ma-0"
								hide-details
								mandatory
								row
								v-model="new_product.product_type"
							>
								<v-radio color="blue darken-4" label="Piping" value="piping"></v-radio>
								<v-radio color="blue darken-4" label="Other items" value="other items"></v-radio>
							</v-radio-group>
						</v-col> -->
						<v-col md="12">
							<label for="name" class="btx-label fw-500 mt-2 required">Product Name</label>
							<TextInput hide-details hide-top-margin :disabled="pageLoading" :loading="pageLoading"
								id="serial-number" placeholder="Product Name (English)" :rules="[
									vrules.required(new_product.name_en, 'Product Name'),
									serialNumberError ? false : true,
								]" :class="{
	required: !new_product.name_en,
}" v-model="new_product.name_en"></TextInput>
							<span v-if="serialNumberError" class="red--text text--darken-1 font-small">{{
								serialNumberError
							}}</span>
						</v-col>

						<v-col md="12">
							<label for="product-name" class="btx-label fw-500 mt-2 required"> Department Category </label>
							<SelectInput hide-details :disabled="pageLoading" :loading="pageLoading"
								:items.sync="department" id="name" v-model="new_product.deptCategory" item-value="id"
								item-text="text" placeholder="Department Category"
								:rules="[vrules.required(new_product.deptCategory, 'Department Category')]"
								:class="{ required: !new_product.deptCategory }">
							</SelectInput>
							<!-- <TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="product-name"
								placeholder="Department Category "
								v-model="new_product.name"
								:rules="[vrules.required(new_product.name, 'Department Category ')]"
								:class="{
									required: !new_product.name,
								}"
							>
							</TextInput> -->
						</v-col>
						<v-col md="12">
							<label for="product-name" class="btx-label fw-500 mt-2 required"> Budget Category </label>
							<AutoCompleteInput hide-details :disabled="pageLoading" :loading="pageLoading"
								:items.sync="othercats" id="name" v-model="new_product.otherCategory" item-value="value"
								item-text="text" placeholder="Budget Category"
								:rules="[vrules.required(new_product.otherCategory, 'Budget Category')]"
								:class="{ required: !new_product.otherCategory }">
							</AutoCompleteInput>
							<!-- <TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="product-name"
								placeholder="Department Category "
								v-model="new_product.name"
								:rules="[vrules.required(new_product.name, 'Department Category ')]"
								:class="{
									required: !new_product.name,
								}"
							>
							</TextInput> -->
						</v-col>
						<!-- <v-col md="12">
							<label for="group" class="btx-label fw-500 mt-2"> Category </label>
							<AutoCompleteInput
								hide-top-margin
								hide-details
								:items="groupList"
								:disabled="pageLoading"
								:loading="pageLoading"
								id="group"
								:rules="[vrules.required(new_product.group, 'Category')]"
								:class="{
									required: !new_product.group,
								}"
								placeholder="Category"
								v-model="new_product.group"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageCategoryDialog = true"
							></AutoCompleteInput>
						</v-col> -->
						<v-col md="3">
							<label class="btx-label fw-500 mt-2 required"> Primary (sales) UOM </label>
							<AutoCompleteInput hide-top-margin hide-details :items="primarySalesUOMList"
								@change="selectprimary(new_product.primary_sales_uom)" :loading="pageLoading" id="supplier"
								:rules="[vrules.required(new_product.primary_sales_uom, 'Supplier')]" :class="{
									required: !new_product.primary_sales_uom,
								}" placeholder="Primary (sales) UOM" v-model="new_product.primary_sales_uom"></AutoCompleteInput>
						</v-col>
						<v-col md="3">
							<label  class="btx-label fw-500 mt-2 required"> Secondary (purchase) UOM </label>
							<AutoCompleteInput hide-top-margin hide-details :items="primarySalesUOMList"
								@change="selectsecondary(new_product.secondary_purchase_uom)" :loading="pageLoading"
								id="supplier" :rules="[vrules.required(new_product.secondary_purchase_uom, 'Supplier')]"
								:class="{
									required: !new_product.secondary_purchase_uom,
								}" placeholder="Secondary (purchase) UOM" v-model="new_product.secondary_purchase_uom"></AutoCompleteInput>
						</v-col>
						<v-col md="3">
							<label class="btx-label fw-500 mt-2 required"> Unit Description (Primary sales UOM) </label>

							<TextInput hide-top-margin hide-details :disabled="pageLoading" :loading="pageLoading"
								id="supplier-code" :rules="[vrules.required(new_product.unit_description_psu, 'Supplier')]"
								:class="{
									required: !new_product.unit_description_psu,
								}" placeholder="Unit Description ( Primary sales UOM )" v-model="new_product.unit_description_psu"></TextInput>
						</v-col>
						<v-col md="3">
							<label class="btx-label fw-500 required"> Unit Value </label>
							<NumberInput hide-details type="number" :loading="pageLoading" id="unit_description"
								:value="'13'" v-model="new_product.unit_value" :rules="[
									vrules.required(
										new_product.unit_value,
										'Department Category'
									),
								]" :class="{ required: !new_product.unit_value }" placeholder="Quantity">
							</NumberInput>
						</v-col>
						<v-col md="4">
							<label for="supplier" class="btx-label fw-500 mt-2"> Supplier </label>
							<AutoCompleteInput hide-top-margin hide-details disabled :items="supplierLists"
								:loading="pageLoading" item-text="barcode" item-value="name" id="supplier"
								:rules="[vrules.required(new_product.supplier, 'Supplier')]" :class="{
									required: !new_product.supplier,
								}" placeholder="new_product.supplier" v-model="new_product.supplier"></AutoCompleteInput>
						</v-col>

						<v-col md="2">
							<label for="supplier" class="btx-label fw-500 mt-2"> Active </label>
							<v-switch class="mt-1" color="#2196F3" :value="false" :ripple="false" hide-details inset
								v-model="new_product.supplier_status"></v-switch>
						</v-col>
						<v-col md="3">
							<label  class="btx-label fw-500 "> <v-icon
									color="blue">mdi-circle</v-icon>Primary (Sales) Price </label>
							<QuantityInput hide-top-margin hide-details :disabled="pageLoading" :loading="pageLoading"
								@keyup="searchPrimary()" id="supplier-price"
								:rules="[vrules.required(new_product.primary_sales_price, 'Purchasing Price')]" :class="{
									required: !new_product.primary_sales_price,
								}" placeholder="Supplier Price" v-model="new_product.primary_sales_price"></QuantityInput>
						</v-col>
						<v-col md="3">
							<label  class="btx-label fw-500 "> <v-icon
									color="red">mdi-circle</v-icon>Secondary (Purchase) Price </label>
							<QuantityInput hide-top-margin hide-details :disabled="pageLoading" :loading="pageLoading"
								@keyup="searchSecondary()" id="supplier-price"
								:rules="[vrules.required(new_product.secondary_purchase_price, 'Purchasing Price')]" :class="{
									required: !new_product.secondary_purchase_price,
								}" placeholder="Supplier Price" v-model="new_product.secondary_purchase_price"></QuantityInput>
						</v-col>
						<v-col md="12">

							<h4 style="text-align:left;font-weight: bold;">Warehouse Quantity</h4>

						</v-col>

						<v-col md="3">
							<label for="supplier" class="btx-label fw-500 "> <v-icon color="blue">mdi-circle</v-icon>
								Primary
							</label>
							<NumberInput hide-top-margin hide-details :loading="pageLoading" id="supplier"
								placeholder="Quantity" v-model="new_product.primary_quantity"></NumberInput>
						</v-col>
						<div class="mr-5 mt-12">
							<h6>{{ new_product.primary_quantity }} {{
								new_product.primary_sales_uom
								? new_product.primary_sales_uom
								: ""
							}}</h6>
						</div>

						<v-col md="3">
							<label for="supplier" class="btx-label fw-500 "> <v-icon color="red">mdi-circle</v-icon>
								Secondary </label>
							<NumberInput hide-top-margin hide-details :loading="pageLoading" id="supplier"
								placeholder="Quantity" v-model="new_product.secondary_quantity"></NumberInput>

						</v-col>

						<div class="mr-5 mt-12">
							<h6>{{ new_product.secondary_quantity  ?
								new_product.secondary_quantity : "" }} {{
		new_product.secondary_purchase_uom
		? new_product.secondary_purchase_uom
		: ""
	}}</h6>
						</div>






					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn :disabled="pageLoading" depressed tile @click="$emit('close', true)"> Close</v-btn>
				<v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text" depressed color="blue darken-4"
					tile @click="updateOrCreate()">
					Save
				</v-btn>
			</template>
		</Dialog>
		<!-- <ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory> -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SelectInput from "@/view/components/SelectInput";
import { QUERY } from "@/core/services/store/request.module";

import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import { SET_LINEITEMS } from "@/core/services/store/lineitem.module";
import { CREATE_SUPPLIER_PRODUCT } from "@/core/lib/product.lib";
import { ValidateSerialNumber, GenrateSerialNumber } from "@/core/lib/common.lib";
// import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";

import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import ManageCategory from "@/view/components/Manage-Category";
import Dialog from "@/view/components/Dialog";
export default {
	name: "product-create-shortcut",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		supplierId: {
			type: [Number,String],
			default: 0,
		},
		supplierLists: {
			type: Array,
			default: () => {
				return [];
			},
		},

	},
	data() {
		return {
			pageLoading: false,
			formValid: true,
			othercats:[],
			primarySalesUOMList: [],
			manageCategoryDialog: false,
			serialNumberError: null,
			department: [],
			groupList: [],
			vendorList: [],
			supplier_id: null,
			supplier_price: 0,
			supplier_code: null,
			new_product: {
				product_type: "piping",
				name_en: null,
				deptCategory: null,
				otherCategory:null,
				unit_value: 1,
				supplier: null,
				primary_sales_uom: null,
				secondary_purchase_uom: null,
				supplier_status: false,
				secondary_purchase_price: null,
				primary_sales_price: null,
				secondary_quantity: null,
				primary_quantity: null

			},
		};
	},
	components: {
		TextInput,
		QuantityInput,
		AutoCompleteInput,
		SelectInput,
		// ManageCategory,
		Dialog,
		NumberInput
	},

	methods: {
		async updateOrCreate() {
			if (!this.$refs.inventoryForm.validate()) {
				return false;
			}
			try {
				this.pageLoading = true;


				const customer = await CREATE_SUPPLIER_PRODUCT(this.new_product);
				console.log(customer)

				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Product Created Successfully." },
				]);
				this.$emit("close", true);
				this.$emit("success", true);

			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
			console.log(this.new_product)
		},
		selectsecondary(value) {
			console.log(value, 'value')
			if (value == this.new_product.primary_sales_uom) {
				this.new_product.secondary_purchase_uom = null;
				return false;

			}

		},
		selectprimary(value) {
			if (value == this.new_product.secondary_purchase_uom) {
				this.new_product.primary_sales_uom = null;
				return false;

			}

		},
		searchPrimary() {
			if (this.new_product.supplier_status == true) {
				this.new_product.secondary_purchase_price =
					this.new_product.unit_value * this.new_product?.primary_sales_price;
				this.new_product.primary_sales_price =
					this.new_product?.primary_sales_price;
			}
		},
		searchSecondary() {
			if (this.new_product.supplier_status == true) {
				this.new_product.primary_sales_price =
					this.new_product?.secondary_purchase_price / this.new_product.unit_value;
				this.new_product.secondary_purchase_price =
					this.new_product?.secondary_purchase_price;
			}
		},
		selectUoms() {

			const _this = this;
			this.$store
				.dispatch(QUERY, {
					url: `uoms`,
				})
				.then((data) => {
					_this.primarySalesUOMList = data;

					/*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
					  console.log(_this.purchaseOrder.supplier.barcode)     */
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.timerLoading = false;
				});

		},
		getCategories(param) {
			this.groupList = param;
		},
		getData() {
			this.$store
				.dispatch(QUERY, {
					url: "data",
				})
				.then((data) => {
					this.department = data.departments;
					this.othercats = data.othercats;

					console.log(this.department, 'department')


				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.timerLoading = false;
				});
		},

		validateSerialNumber(serial_number, type) {
			const _this = this;
			const params = {
				type: type,
				serial_number: serial_number,
			};
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				_this.serialNumberError = null;
				ValidateSerialNumber(`validate-serialnumber`, params)
					.then((output) => {
						if (output.exists) {
							_this.serialNumberError = `${serial_number} already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		async getSerialNumber() {
			const _this = this;
			_this.serialNumberError = null;
			let type = _this.new_product.product_type == "piping" ? "piping" : "other-items";
			const { barcode } = await GenrateSerialNumber(type);
			_this.new_product.serial_number = barcode;
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.supplier_id = this.supplierId;
		this.new_product.supplier = this.supplier_id;
		// this.groupList = this.localDB("groups", []);
		// this.vendorList = this.localDB("suppliers", []);
		this.getSerialNumber();
		this.getData();
		this.selectUoms();
	},
};
</script>
